export const l = {
		products: {
				en: 'Products',
				id: 'Produk'
		},
		partners: {
				en: 'Partners',
				id: 'Mitra'
		},
		info: {
				en: 'Info',
				id: 'Info'
		}
}